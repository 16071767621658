<template>
    <div class="detail-read">

        <div class="content">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane :label="'文件' + (index + 1)" :name="item.id + ''" v-for="(item, index) in detailData.fileInfo" :key="index">
                    <el-card  class="" shadow="never">
                        <el-row class="header">
                            <el-col :span="20">
                                <div class="title">
                                    <i class="iconfont icon-pdf" v-if="item.imagetype === 'pdf'"></i>
                                    <i class="iconfont icon-wendangdocument84" v-else></i>
                                  {{item.filename}}
                                </div>
                                <div class="desc">文件大小:{{$fomtFileSize(item.filesize)}}&nbsp;&nbsp;&nbsp;<span v-if="readData.hasData">当前第{{imageIndex + 1}}页/共{{item.total}}页</span></div>
                            </el-col>
                            <el-col :span="4">
                                <div class="title" style="text-align: right">
                                    <!--                        <el-button type="danger" v-if="readData.isPayDown" size="small" >付费下载</el-button>-->
                                    <el-button type="danger"  size="small" @click="checkPay('down')">下载高清文档</el-button>
                                </div>
                            </el-col>
                        </el-row>
                    </el-card>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-card id="read-box-card"  class="box-card"  :body-style="{padding: 0}" shadow="never" v-infinite-scroll="load">
            <div v-if="readData.hasData">
              <PreviewImg :readData="readData" ref="PreviewImg" @checkPay="checkPay('down')"/>
<!--              <el-image-->
<!--                  style="width: 100%; height: 100%"-->
<!--                  :key="index" v-for="(url, index) in readData.urls"-->
<!--                  :src="url"-->
<!--                  lazy-->
<!--                  :preview-src-list="readData.urls">-->
<!--              </el-image>-->
<!--              <div style="text-align: center" v-if="readData.total > 0">共{{readData.total}}页</div>-->
<!--              <el-row v-if="readData.isPay">-->
<!--                <el-col :span="24" style="text-align: center">-->
<!--                  <el-button type="text"  @click="checkPay('down')" >请下载文档继续查看剩余部分</el-button>-->

<!--                </el-col>-->
<!--                <el-col :span="24" style="text-align: center">-->
<!--                  <el-button type="danger" @click="checkPay('down')" >下载文档</el-button>-->

<!--                </el-col>-->
<!--              </el-row>-->
            </div>
            <div v-else>
              <el-empty description="该文件暂不支持在线预览，请尝试下载浏览吧"></el-empty>
            </div>

        </el-card>

    </div>
</template>

<script>
    import { archivesService } from '../../api/archives'
    import PreviewImg from '@/components/PreviewImg.vue'
    export default {
        components: {
          [PreviewImg.name]: PreviewImg
        },
        name: "read",
        data() {
            return {
                count: 5,
                activeName: '',
                aid: '',
                detailData: {
                    fileInfo:[]
                },
                readData: {
                    urls: [],
                    isPay: false,
                    hasData: true,
                    isPayDown: false,
                    service: '',
                    filename: "",
                    imagetype: ""
                },
                // init
            }
        },
      computed: {
          imageIndex() {
            return this.$refs.PreviewImg.imgIndex
          }
      },
        methods: {
            handleClick(a, b) {
                // // console.log('a', a)
                // // console.log('b', b)
                this.getDetail()
            },
            checkPay(type) {
              // console.log('type', type)
                const that = this
                this.$pay.show({
                    id: this.aid,
                    fileId: this.activeName,
                    type,
                    callback() {
                        // debugger
                        that.getDetail()
                    }
                })
            },
            load () {
                // // console.log(222)
                // this.count += 2
            },
            getDetail() {
                archivesService.read({id: this.aid, fileId: this.activeName}).then(res => {
                    // // console.log('res', res)
                  // res.data.urls.forEach((url, index) => {
                  //   res.data.urls[index] = 'http://jya360.chenzeou.com.cn/' + url
                  // })
                    this.readData = res.data

                })
            },
            getArchivesDetail() {
                archivesService.detail({id: this.aid}).then(res => {
                    // // console.log('res', res)
                    this.detailData = res.data
                    const fileId = this.$route.query.fileId
                    this.activeName = (fileId ? fileId : this.detailData.fileInfo[0].id) + ''
                    this.getDetail()
                })
            }
        },
        created() {
            this.aid = this.$route.query.id
            if (!this.aid) {
                this.$message.error('获取数据失败')
                return
            }
            this.getArchivesDetail()

        },

    }
</script>
<style  lang="scss">
    .detail-read{
/*        .content{
            margin-top: -20px;
        }*/
        .el-tabs__header{
            margin: unset !important;
        }
        .desc{
            color: $font_2;
            font-size: 13px;
            margin-top: 10px;
        }
    }

</style>
<style scoped lang="scss">
    .detail-read{
        width: 60%;
        height: 100%;
        margin: 0 auto;
        .box-card{
          //padding-bottom: 100px;
            height: 73%;
            overflow: auto;
            margin-top: -10px;
            .header{
                border-bottom: 1px solid $border_1;
                padding-bottom: 15px;

            }

        }
    }
</style>