<template>
  <div>
    <div class="proview_box" @click="hideMask">
      <div class="img_box">
<!--        <el-image-->
<!--            style="width: 100%; height: 100%"-->
<!--            id="img"-->
<!--            :src="imgPath"-->
<!--            :preview-src-list="filesLists">-->
<!--        </el-image>-->
        <img :src="imgPath"  id='img' width="100%" @load="imgLoad">
<!--        <img :src="imgPath"  id='img' :width="width">-->
      </div>
    </div>
    <div class="handleImg_box">
<!--      <span>222</span>-->
      <div>
<!--        <span>222</span>-->

        <el-button type="warning" size="mini" icon="el-icon-d-arrow-left" @click="changePage(-1)" >上一页</el-button>
        <el-button type="success" size="mini" icon="el-icon-refresh-right"  @click="rotate(1)" >旋转</el-button>
        <el-button type="primary" size="mini" @click="changePage(1)" >
          下一页
          <i class="el-icon-d-arrow-right el-icon--right"></i>
        </el-button>
<!--        <el-button type="warning" icon="el-icon-d-arrow-left" @click="prevOne" circle></el-button>-->
<!--        <el-button type="success" icon="el-icon-refresh-right"  @click="rotate(1)" circle></el-button>-->
<!--        <el-button type="primary" icon="el-icon-d-arrow-right" @click="nextOne" circle></el-button>-->
      </div>

<!--      <div @click="prevOne">-->
<!--&lt;!&ndash;        <img src="../../../../static/img/prev.png" />&ndash;&gt;-->
<!--        <el-button>上一张</el-button>-->
<!--      </div>-->
<!--      <div @click="rotate(0)">-->
<!--        <el-button>左旋转</el-button>-->
<!--&lt;!&ndash;        <img src="../../../../static/img/turn_left.png" />&ndash;&gt;-->
<!--      </div>-->
<!--      <div @click="rotate(1)">-->
<!--        <el-button>右旋转</el-button>-->
<!--&lt;!&ndash;        <img src="../../../../static/img/turn_right.png" />&ndash;&gt;-->
<!--      </div>-->
<!--      <div @click="nextOne">-->
<!--        <el-button>下一张</el-button>-->
<!--&lt;!&ndash;        <img src="../../../../static/img/next.png" />&ndash;&gt;-->
<!--      </div>-->
    </div>
  </div>
</template>
<script>
export default {
  name: 'PreviewImg',
  props: ['readData', 'file'],
  data: function() {
    return {
      imgPath: '',
      imgList: [],
      width: 0,
      height: 0,
      imgIndex: 0,
      loading: false
    }
  },
  mounted() {
  },
  computed: {

    //去掉不是图片的附件
    // imgList() {
    //
    // }
  },
  methods: {
    imgLoad(e) {
      // console.log('111', e)
      this.scrollTop()
      if (this.loading) {
        this.loading.close();
        this.loading = false
      }
    },
    setImgList() {
      const ARR = ["png", "PNG", "jpeg", "JPEG", "bmp", "BMP", "jpg", "JPG", "gif", "GIF"];
      let arrs = '';
      let suffix = '';
      // let type = '';
      let newList = [];
      // console.log('this.filesLists', this.filesLists)
      this.readData.urls.forEach((item) => {
        arrs = item.split('.');
        // arrs = item.name.split('.');
        suffix = arrs[arrs.length - 1];
        newList.push({
          path: item,
          type: suffix
        })
        // type = item.type ? item.type : item.raw ? item.raw.type : suffix;
        // ARR.some(items => {
        //   if (suffix.includes(items)) {
        //     newList.push({
        //       path: item,
        //     })
        //   }
        // })
      })
      this.imgList =  newList;
      // this.getImgIndex();
      this.seeAttachment(this.imgIndex);
    },
    //通过父组件传入的file获取当前查看的图片index
    getImgIndex() {
      let that = this;
      // console.log('that.imgList', that.imgList)
      that.imgList.forEach((item, index) => {
        if(that.file == item.path){
          that.imgIndex = index;
        }
      })
    },
    //隱藏查看圖片
    hideMask() {
      this.$emit('hideMask', false);
    },
    scrollTop() {
      document.getElementById('read-box-card').scroll(0 ,0)
    },
    stopEvent(event) {
      //阻止冒泡事件
      //取消事件冒泡
      let e = event; //若省略此句，下面的e改为event，IE运行可以，但是其他浏览器就不兼容
      if (e && e.stopPropagation) {
        e.stopPropagation();
      } else if (window.event) {
        window.event.cancelBubble = true;
      }
    },
    changePage(index) {
      const imgIndex = this.imgIndex + index
      if (imgIndex < 0 || imgIndex >= this.imgList.length) {
        if (index > 0 && this.readData.isPay) {
          // console.log(111)
          this.$alert('请下载文档继续查看剩余部分', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              // console.log('actios', action)
              if (action === 'confirm') {
                this.$emit('checkPay')
                // this.$parent.checkPay('down')
              }
              // this.$message({
              //   type: 'info',
              //   message: `action: ${ action }`
              // });
            }
          });
        }
        return
      }
      // this.imgIndex = this.imgIndex + 1;
      // let img = document.getElementById('img')
      // img.style.transform = 'rotate(0deg)';
      this.imgIndex = imgIndex;
      this.seeAttachment(this.imgIndex);
    },
    // //上一張
    // prevOne() {
    //   if (this.imgIndex == 0) {
    //     return false;
    //   }
    //   // let img = document.getElementById('img')
    //   // img.style.transform = 'rotate(0deg)';
    //   this.imgIndex = this.imgIndex - 1;
    //   this.seeAttachment(this.imgIndex);
    // },
    // //下一張
    // nextOne() {
    //   let listLength = this.imgList.length - 1;
    //   if (this.imgIndex >= listLength) {
    //     return false;
    //   }
    //   // let img = document.getElementById('img')
    //   // img.style.transform = 'rotate(0deg)';
    //   this.imgIndex = this.imgIndex + 1;
    //   this.seeAttachment(this.imgIndex);
    // },
    //右转
    rotate(t) {
      let img = document.getElementById('img')
      var reg = /(rotate\([\-\+]?((\d+)(deg))\))/i;
      var wt = img.style['-webkit-transform'],
          wts = wt.match(reg);
      var $3 = RegExp.$3;
      var current = $3 ? parseInt($3) : 0;
      if (t == 0) {
        current = current == 0 ? 360 : current;
        current = (current - 90) % 360;
      } else {
        current = (current + 90) % 360;
      }
      img.style.transform = 'rotate(' + current + 'deg)';
    },
    seeAttachment(index = 0) {
      if (this.imgList.length == 0) {
        return false;
      }
      this.loading = this.$loading({
        lock: true,
        text: '正在加载页面中，请稍候...',
        spinner: 'el-icon-loading',
      });
      let that = this;
      let basePath = "";
      // let basePath = "https://jya360.com";
      that.imgPath = basePath + that.imgList[index]['path'];
      let img_url = basePath + that.imgList[index]['path'];
      // 创建对象
      var img = new Image();
      // 改变图片的src
      img.src = img_url;
      // 定时执行获取宽高
      var check = function() {
        // 只要任何一方大于0
        // 表示已经服务器已经返回宽高
        if (img.width > 0 || img.height > 0) {
          let wdt = document.body.offsetWidth;
          let hgt = document.body.offsetHeight;
          let ratio = 1;
          if (img.width > img.height && img.width > wdt * ratio) {
            img.height = img.height * wdt * ratio / img.width;
            img.width = wdt * ratio;
            // console.log('宽大于高', img)
          } else if (img.height > img.width && img.height > hgt * ratio) {
            img.width = img.width * hgt * ratio / img.height;
            if (img.width > wdt) {
              img.width = wdt;
            }
            img.height = hgt * ratio;
            // console.log('高大于宽', img)
          } else {
            img.height = img.height * wdt * ratio / img.width
            img.width = wdt * ratio
            // console.log('正常', img)
          }
          that.width = img.width;
          that.height = img.height;
          clearInterval(set);
        }
      };
      var set = setInterval(check, 40);
    },
  },
  watch: {
    'readData.urls': {
      deep: true,
      handler() {
        this.setImgList()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.handleImg_box {
  position: fixed;
  bottom: 40px;
  /* left: 49%; */
  z-index: 100;
  width: 100%;
  text-align: center;
  /* padding: 1rem 0; */
  /* margin: 0 auto; */
  display: flex;
  //justify-content: center;
  left: 0;
  justify-content: space-around;
}

.handleImg_box div {
  font-size: 0;
}

.handleImg_box div img {
  width: 3rem;
}
</style>